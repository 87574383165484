import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ListBanner = () => {
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);

  const UploadImage = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setBanner(reader.result);
    };
  };

  const EditBanner = async () => {
    try {
      var formdata = new FormData();
      formdata.append("Banner", file);
      formdata.append("BannerUrl", banner);

      const response = await fetch(Global.API_URL + "Banner/EditBanner", {
        method: "PUT",
        body: formdata,
        redirect: "follow",
      });
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const GetBanner = async () => {
      try {
        const response = await fetch(Global.API_URL + "Banner/GetBanner", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setBanner(result.message.bannerImage);
            setLoading(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetBanner();
  }, []);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <button onClick={EditBanner}>Submit</button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group">
                      <img style={{ height: "200px" }} src={file ? banner : Global.PHOTO_URL + banner} alt="" />
                    <br />
                    <br />
                    <input type="file" onChange={UploadImage} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListBanner;
