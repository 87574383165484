import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../Global";
import { useDispatch } from "react-redux";
import { loginAdmin, logoutAdmin } from "../../store/slices/admin-slice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(Global.API_URL + "User/Login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          dispatch(loginAdmin(result.message));
          navigate("/admin/menu");
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          setLoading(false);
        }
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    dispatch(logoutAdmin());
  }, [dispatch]);

  return (
    <div className="login-page">
      <form onSubmit={(e) => Login(e)}>
        <div className="login-card">
          <h1>Admin Portal</h1>

          <input
            type="text"
            placeholder="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit" disabled={loading}>
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
