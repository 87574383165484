import React, { useState, useEffect, useRef, Fragment } from "react";
import Layout from "../../layout/Layout";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ManageWidget = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const pageLimit = 10;
  const activeProductPag = useRef(0);
  const activeCategoryPag = useRef(0);

  const [widget, setWidget] = useState({
    widgetTitle: "",
    widgetSubtitle: "",
    widgetTypeId: 1,
    products: [],
    categories: [],
  });
  const [loading, setLoading] = useState(false);
  const [isOpenProduct, setIsOpenProduct] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);

  const [products, setProducts] = useState([]);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [loadingProduct, setLoadingProduct] = useState(true);

  const [categories, setCategories] = useState([]);
  const [totalCategoryCount, setTotalCategoryCount] = useState(0);
  const [loadingCategory, setLoadingCategory] = useState(true);

  const UnassignProduct = (productId) => {
    let array = [...widget.products];
    array = array.filter((product) => product.productId !== productId);
    setWidget((prevState) => ({
      ...prevState,
      products: array,
    }));
  };

  const AssignProduct = (product) => {
    let array = [...widget.products];
    array.push(product);
    setWidget((prevState) => ({
      ...prevState,
      products: array,
    }));
  };

  const UnassignCategory = (categoryId) => {
    let array = [...widget.categories];
    array = array.filter((category) => category.categoryId !== categoryId);
    setWidget((prevState) => ({
      ...prevState,
      categories: array,
    }));
  };

  const AssignCategory = (category) => {
    let array = [...widget.categories];
    array.push(category);
    setWidget((prevState) => ({
      ...prevState,
      categories: array,
    }));
  };

  let productPaggination = Array(Math.ceil(totalProductCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  const ChangeProductPage = (i) => {
    activeProductPag.current = i;

    ChangeProductData(activeProductPag.current * pageLimit);
  };

  const ChangeProductData = async (skip) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Widget/GetProducts?skip=" +
          skip +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          setProducts(result.message);
          setTotalProductCount(result.count);
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error.Message || "Something went wrong");
    }
  };

  let categoryPaggination = Array(Math.ceil(totalCategoryCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  const ChangeCategoryPage = (i) => {
    activeCategoryPag.current = i;

    ChangeCategoryData(activeCategoryPag.current * pageLimit);
  };

  const ChangeCategoryData = async (skip) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Widget/GetCategories?skip=" +
          skip +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          setCategories(result.message);
          setTotalCategoryCount(result.count);
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    const GetCategories = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Widget/GetCategories?skip=0&take=" + pageLimit,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setCategories(result.message);
            setTotalCategoryCount(result.count);
            setLoadingCategory(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetCategories();
  }, []);

  useEffect(() => {
    const GetProducts = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Widget/GetProducts?skip=0&take=" + pageLimit,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setProducts(result.message);
            setTotalProductCount(result.count);
            setLoadingProduct(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetProducts();
  }, []);

  const EditWidget = async () => {
    try {
      let newProducts = [],
        newCategories = [];
      if (widget.widgetTypeId === 1) {
        widget.products.forEach((item) => {
          newProducts.push(item.productId);
        });
      }

      if (widget.widgetTypeId === 2) {
        widget.categories.forEach((item) => {
          newCategories.push(item.categoryId);
        });
      }

      const response = await fetch(Global.API_URL + "Widget/EditWidget/" + id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          widgetTitle: widget.widgetTitle,
          widgetSubtitle: widget.widgetSubtitle,
          products: newProducts,
          categories: newCategories,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          navigate("/admin/widgets");
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error);
      console.log(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    const GetWidget = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Widget/GetWidget/" + id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setWidget(result.message);
              setLoading(false);
            } else {
              toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
            }
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } catch (error) {
          console.log(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetWidget();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/widgets">Back</NavLink>
          <button onClick={EditWidget}>Submit</button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Widget Title{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Widget title"
                      value={widget.widgetTitle}
                      onChange={(e) =>
                        setWidget((prevState) => ({
                          ...prevState,
                          widgetTitle: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Widget Subtitle
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Widget subtitle"
                      value={widget.widgetSubtitle}
                      onChange={(e) =>
                        setWidget((prevState) => ({
                          ...prevState,
                          widgetSubtitle: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                {widget.widgetTypeId === 1 && (
                  <div className="table-responsive">
                    <label>Products</label>
                    <button
                      className="btn-assign"
                      onClick={() => setIsOpenProduct(true)}
                    >
                      {" "}
                      +{" "}
                    </button>
                    {isOpenProduct && (
                      <div className="modal">
                        <div className="modal-content">
                          {loadingProduct ? (
                            <div className="loader"></div>
                          ) : (
                            <Fragment>
                              <div className="modal-title">
                                <h4>Products</h4>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => setIsOpenProduct(false)}
                                >
                                  {" "}
                                  <path
                                    d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"
                                    fill="black"
                                  />{" "}
                                </svg>
                              </div>
                              <div className="table-responsive">
                                <div>
                                  <table className="card-table shadow-hover dataTable">
                                    <thead>
                                      <tr role="row">
                                        <th>Id</th>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                        <th>Product Image</th>
                                        <th className="bg-none"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {products.map((product, index) => (
                                        <tr role="row" key={index}>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              {product.productId}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              {product.productName}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              {product.categoryName}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              <img
                                                src={
                                                  Global.PHOTO_URL +
                                                  product.productImage
                                                }
                                                alt="product"
                                                height="150"
                                              />
                                            </span>
                                          </td>
                                          <td>
                                            <div className="dropdown dropdown">
                                              <div
                                                className="btn-link i-false dropdown-toggle"
                                                id="react-aria3069930207-3"
                                                aria-expanded="false"
                                                data-bs-toggle="dropdown"
                                              >
                                                {!widget.products.some(
                                                  (p) =>
                                                    p.productId ===
                                                    product.productId
                                                ) && (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    viewBox="0 0 16 16"
                                                    onClick={() =>
                                                      AssignProduct(product)
                                                    }
                                                  >
                                                    {" "}
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />{" "}
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />{" "}
                                                  </svg>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <div className="content-pagination">
                                    <div className="pagination-info">
                                      Showing {products.length} of {totalProductCount} entries
                                    </div>
                                    <div className="pagination-button">
                                      <button
                                        className="previous"
                                        onClick={() =>
                                          activeProductPag.current > 0 &&
                                          ChangeProductPage(
                                            activeProductPag.current - 1
                                          )
                                        }
                                      >
                                        Previous
                                      </button>
                                      <span>
                                        {productPaggination.map(
                                          (number, index) => (
                                            <button
                                              className={
                                                activeProductPag.current ===
                                                index
                                                  ? "current active"
                                                  : "current"
                                              }
                                              key={index}
                                              onClick={() =>
                                                ChangeProductPage(index)
                                              }
                                            >
                                              {number}
                                            </button>
                                          )
                                        )}
                                      </span>
                                      <button
                                        className="next"
                                        onClick={() =>
                                          activeProductPag.current + 1 <
                                            productPaggination.length &&
                                          ChangeProductPage(
                                            activeProductPag.current + 1
                                          )
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>{" "}
                      </div>
                    )}
                    <div>
                      <table className="card-table shadow-hover dataTable">
                        <thead>
                          <tr role="row">
                            <th>Id</th>
                            <th>Product Name</th>
                            <th>Category Name</th>
                            <th className="bg-none"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {widget.products.map((product, index) => (
                            <tr role="row" key={index}>
                              <td>
                                <span className="fs-16 font-w500 text-nowrap">
                                  {product.productId}
                                </span>
                              </td>
                              <td>
                                <span className="fs-16 font-w500 text-nowrap">
                                  {product.productName}
                                </span>
                              </td>
                              <td>
                                <span className="fs-16 font-w500 text-nowrap">
                                  {product.categoryName}
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown">
                                  <div
                                    className="btn-link i-false dropdown-toggle"
                                    id="react-aria3069930207-3"
                                    aria-expanded="false"
                                    data-bs-toggle="dropdown"
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() =>
                                        UnassignProduct(product.productId)
                                      }
                                    >
                                      {" "}
                                      <path
                                        d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"
                                        fill="black"
                                      />{" "}
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {widget.widgetTypeId === 2 && (
                  <div className="table-responsive">
                    <label>Categories</label>
                    <button
                      className="btn-assign"
                      onClick={() => setIsOpenCategory(true)}
                    >
                      {" "}
                      +{" "}
                    </button>
                    {isOpenCategory && (
                      <div className="modal">
                        <div className="modal-content">
                          {loadingCategory ? (
                            <div className="loader"></div>
                          ) : (
                            <Fragment>
                              <div className="modal-title">
                                <h4>Categories</h4>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => setIsOpenCategory(false)}
                                >
                                  {" "}
                                  <path
                                    d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"
                                    fill="black"
                                  />{" "}
                                </svg>
                              </div>
                              <div className="table-responsive">
                                <div>
                                  <table className="card-table shadow-hover dataTable">
                                    <thead>
                                      <tr role="row">
                                        <th>Id</th>
                                        <th>Category Name</th>
                                        <th>Category Image</th>
                                        <th className="bg-none"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {categories.map((category, index) => (
                                        <tr role="row" key={index}>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              {category.categoryId}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              {category.categoryName}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="fs-16 font-w500 text-nowrap">
                                              <img
                                                src={
                                                  Global.PHOTO_URL +
                                                  category.categoryImage
                                                }
                                                alt="category"
                                                height="75"
                                              />
                                            </span>
                                          </td>
                                          <td>
                                            <div className="dropdown dropdown">
                                              <div
                                                className="btn-link i-false dropdown-toggle"
                                                id="react-aria3069930207-3"
                                                aria-expanded="false"
                                                data-bs-toggle="dropdown"
                                              >
                                                {!widget.categories.some(
                                                  (c) =>
                                                    c.categoryId ===
                                                    category.categoryId
                                                ) && (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    viewBox="0 0 16 16"
                                                    onClick={() =>
                                                      AssignCategory(category)
                                                    }
                                                  >
                                                    {" "}
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />{" "}
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />{" "}
                                                  </svg>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <div className="content-pagination">
                                    <div className="pagination-info">
                                      Showing {categories.length} of {totalCategoryCount} entries
                                    </div>
                                    <div className="pagination-button">
                                      <button
                                        className="previous"
                                        onClick={() =>
                                          activeCategoryPag.current > 0 &&
                                          ChangeCategoryPage(
                                            activeCategoryPag.current - 1
                                          )
                                        }
                                      >
                                        Previous
                                      </button>
                                      <span>
                                        {categoryPaggination.map(
                                          (number, index) => (
                                            <button
                                              className={
                                                activeCategoryPag.current ===
                                                index
                                                  ? "current active"
                                                  : "current"
                                              }
                                              key={index}
                                              onClick={() =>
                                                ChangeCategoryPage(index)
                                              }
                                            >
                                              {number}
                                            </button>
                                          )
                                        )}
                                      </span>
                                      <button
                                        className="next"
                                        onClick={() =>
                                          activeCategoryPag.current + 1 <
                                            categoryPaggination.length &&
                                          ChangeCategoryPage(
                                            activeCategoryPag.current + 1
                                          )
                                        }
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>{" "}
                      </div>
                    )}
                    <div>
                      <table className="card-table shadow-hover dataTable">
                        <thead>
                          <tr role="row">
                            <th>Id</th>
                            <th>Category Name</th>
                            <th>Category Image</th>
                            <th className="bg-none"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {widget.categories.map((category, index) => (
                            <tr role="row" key={index}>
                              <td>
                                <span className="fs-16 font-w500 text-nowrap">
                                  {category.categoryId}
                                </span>
                              </td>
                              <td>
                                <span className="fs-16 font-w500 text-nowrap">
                                  {category.categoryName}
                                </span>
                              </td>
                              <td>
                                <span className="fs-16 font-w500 text-nowrap">
                                  <img
                                    src={
                                      Global.PHOTO_URL + category.categoryImage
                                    }
                                    alt="category"
                                    height="75"
                                  />
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown">
                                  <div
                                    className="btn-link i-false dropdown-toggle"
                                    id="react-aria3069930207-3"
                                    aria-expanded="false"
                                    data-bs-toggle="dropdown"
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={() =>
                                        UnassignCategory(category.categoryId)
                                      }
                                    >
                                      {" "}
                                      <path
                                        d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"
                                        fill="black"
                                      />{" "}
                                    </svg>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageWidget;
