import React, { useEffect, useState, Fragment } from "react";
import Layout from "../layout/Layout";
import HeroSlider from "../wrappers/HeroSlider";
import HomeGrid from "../wrappers/HomeGrid";
import HomeCategories from "../wrappers/HomeCategories";
import HomeCarousel from "../wrappers/HomeCarousel";
// import home from "../data/home";
// import home1 from "../data/home1";
import { toast } from "react-toastify";
import { Global } from "../Global";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [slides, setSlides] = useState([]);
  const [firstWidget, setFirstWidget] = useState({
    widgetTitle: "",
    widgetSubtitle: "",
    products: [],
  });
  const [secondWidget, setSecondWidget] = useState({
    widgetTitle: "",
    widgetSubtitle: "",
    categories: [],
  });
  const [thirdWidget, setThirdWidget] = useState({
    widgetTitle: "",
    widgetSubtitle: "",
    products: [],
  });
  const [fourthWidget, setFourthWidget] = useState({
    widgetTitle: "",
    widgetSubtitle: "",
    products: [],
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const GetWidgets = async () => {
      try {
        const response = await fetch(Global.API_URL + "Client/GetWidgets", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setSlides(result.message.slides);
            setFirstWidget(result.message.firstProductWidget);
            setSecondWidget(result.message.catgoryWidget);
            setThirdWidget(result.message.secondProductWidget);
            setFourthWidget(result.message.thirdProductWidget);
            setIsLoading(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetWidgets();
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <div className="home-loader">
          <div></div>
          <div></div>
        </div>
      ) : (
        <Fragment>
          <HeroSlider mt="mb-155" data={slides} />

          <HomeGrid data={firstWidget} />

          <HomeCategories data={secondWidget} />

          <HomeCarousel data={thirdWidget} />

          <HomeGrid data={fourthWidget} />
        </Fragment>
      )}
    </Layout>
  );
};

export default Home;
