import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ManageCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [category, setCategory] = useState({
    categoryName: "",
    categoryImage: "",
  });
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);

  const UploadImage = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setCategory((prevState) => ({
        ...prevState,
        categoryImage: reader.result,
      }));
    };
  };

  const AddCategory = async () => {
    try {
      var formdata = new FormData();
      formdata.append("CategoryName", category.categoryName);
      formdata.append("CategoryImage", file);

      const response = await fetch(
        Global.API_URL + "Category/AddCategory",
        {
          method: "POST",
          body: formdata,
          redirect: "follow",
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
        navigate("/admin/categories");
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EditCategory = async () => {
    try {
      var formdata = new FormData();
      formdata.append("CategoryName", category.categoryName);
      formdata.append("CategoryImage", file);
      formdata.append("CategoryImageUrl", category.categoryImage);

      const response = await fetch(Global.API_URL + "Category/EditCategory/" + id, {
        method: "PUT",
        body: formdata,
        redirect: "follow",
      });
      const result = await response.json();
  
      if (result.code === 0) {
        toast.success(result.message);
        navigate("/admin/categories");
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const GetCategory = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Category/GetCategory/" + id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setCategory(result.message);
              setLoading(false);
            } else {
              toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
            }
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } catch (error) {
          console.log(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetCategory();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/categories">Back</NavLink>
          <button onClick={parseInt(id) === 0 ? AddCategory : EditCategory}>
            Submit
          </button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Category Name{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Category name"
                      value={category.categoryName}
                      onChange={(e) =>
                        setCategory((prevState) => ({
                          ...prevState,
                          categoryName: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Image{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <br />
                    {(file || category.categoryImage !== "") && (
                      <img
                        style={{ height: "200px" }}
                        src={file ? category.categoryImage : Global.PHOTO_URL + category.categoryImage}
                        alt=""
                      />
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={UploadImage} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageCategory;
