import { Suspense, Fragment, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Global } from "./Global";
import { toast } from "react-toastify";
import { setMenu, setBanner } from "./store/slices/data-slice";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import Home from "./pages/Home";
import Shop from "./pages/Shop";
import Wishlist from "./pages/Wishlist";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Order from "./pages/Order";
import Product from "./pages/Product";

import Login from "./admin/pages/Login";
import ForgotPassword from "./admin/pages/ForgotPassword";
import ResetPassword from "./admin/pages/ResetPassword";

import ListWidget from "./admin/pages/widget/ListWidget";
import ListSlide from "./admin/pages/slide/ListSlide";
import ListBanner from "./admin/pages/banner/ListBanner";
import ListCategory from "./admin/pages/category/ListCategory";
import ListProduct from "./admin/pages/product/ListProduct";
import ListOrder from "./admin/pages/order/ListOrder";
import ListCity from "./admin/pages/city/ListCities";
import ListMenu from "./admin/pages/menu/ListMenu";
import ListUser from "./admin/pages/user/ListUser";

import ManageMenu from "./admin/pages/menu/ManageMenu";
import ManageCity from "./admin/pages/city/ManageCity";
import ManageCategory from "./admin/pages/category/ManageCategory";
import ManageSlide from "./admin/pages/slide/ManageSlide";
import ManageProduct from "./admin/pages/product/ManageProduct";
import ManageOrder from "./admin/pages/order/ManageOrder";
import ManageUser from "./admin/pages/user/ManageUser";
import ManageWidget from "./admin/pages/widget/ManageWidget";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { isLogged } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  useEffect(() => {
    const GetMenu = async () => {
      try {
        const response = await fetch(Global.API_URL + "Client/GetMenu", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            dispatch(setMenu(result.message));
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    const GetBanner = async () => {
      try {
        const response = await fetch(Global.API_URL + "Client/GetBanner", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            dispatch(setBanner(result.message));
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetMenu();
    GetBanner();
  }, [dispatch]);

  return (
    <Fragment>
      <Suspense>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
          <Route
            path={process.env.PUBLIC_URL + "/shop/category/:title/:id"}
            element={<Shop />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/wishlist"}
            element={<Wishlist />}
          />
          <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
          <Route
            path={process.env.PUBLIC_URL + "/checkout"}
            element={<Checkout />}
          />
          <Route path={process.env.PUBLIC_URL + "/order"} element={<Order />} />
          <Route
            path={process.env.PUBLIC_URL + "/shop-product/:id"}
            element={<Product />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/admin/login"}
            element={<Login />}
          />

          {!isLogged ? (
            <Fragment>
              <Route
                path={process.env.PUBLIC_URL + "/admin/forgot-password"}
                element={<ForgotPassword />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/reset-password"}
                element={<ResetPassword />}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Route
                path={process.env.PUBLIC_URL + "/admin/widgets"}
                element={<ListWidget />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/slides"}
                element={<ListSlide />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/banner"}
                element={<ListBanner />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/categories"}
                element={<ListCategory />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/products"}
                element={<ListProduct />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/orders"}
                element={<ListOrder />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/cities"}
                element={<ListCity />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/menu"}
                element={<ListMenu />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/users"}
                element={<ListUser />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/admin/menu/:id"}
                element={<ManageMenu />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/city/:id"}
                element={<ManageCity />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/category/:id"}
                element={<ManageCategory />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/slide/:id"}
                element={<ManageSlide />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/product/:id"}
                element={<ManageProduct />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/order/:id"}
                element={<ManageOrder />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/user/:id"}
                element={<ManageUser />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/admin/widget/:id"}
                element={<ManageWidget />}
              />
            </Fragment>
          )}

          <Route path={process.env.PUBLIC_URL + "*"} element={<Home />} />
        </Routes>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}

export default App;
