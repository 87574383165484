import React, { useEffect, useState, Fragment } from "react";
import Layout from "../layout/Layout";
import ShopGrid from "../wrappers/ShopGrid";
import HomeBanner from "../wrappers/HomeBanner";
// import products from "../data/products";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../Global";

const Shop = () => {
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [showingProducts, setShowingProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const sort = new URLSearchParams(location.search).get("sort");
  const page = new URLSearchParams(location.search).get("page");

  const SortClick = (value) => {
    setIsOpenSort(false);
    navigate(`${location.pathname}?sort=${value}&page=1`);
  };

  const OnPageClick = (value) => {
    navigate(`${location.pathname}?sort=${sort}&page=${value}`);
  };

  useEffect(() => {
    let pageLimit = 20;

    const GetProducts = async () => {
      try {
        const response = await fetch(
          Global.API_URL +
            "Client/GetProducts/" +
            id +
            "?skip=" +
            (page - 1) * pageLimit +
            "&take=" +
            pageLimit +
            "&sort=" +
            sort,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setShowingProducts(result.message);
            setTotalCount(result.count);

            let array = [],
              total = Math.ceil(result.count / pageLimit);
            for (var i = 1; i <= total; i++) {
              array.push(i);
            }
            setTotalPages(array);

            setIsLoading(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    GetProducts();
  }, [sort, page, id]);

  return (
    <Layout>
      {isLoading ? (
        <div className="home-loader">
          <div></div>
          <div></div>
        </div>
      ) : (
        <Fragment>
          <HomeBanner mt="mb-155" />

          <div className="shop-info">
            <p className="shop-items">Home / Shop - {totalCount} items</p>

            <div className="sorting">
              <h6>Sort by:</h6>
              <p>
                {sort === "title-ascending"
                  ? "Product: A-Z"
                  : sort === "title-descending"
                  ? "Product: Z-A"
                  : sort === "price-ascending"
                  ? "Price Low To High"
                  : sort === "price-descending"
                  ? "Price High To Low"
                  : "Default Sorting"}
              </p>
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="18"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsOpenSort(!isOpenSort)}
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
              {isOpenSort && (
                <ul>
                  <li onClick={() => SortClick("new-arrival")}>
                    Default Sorting
                  </li>
                  <li onClick={() => SortClick("price-ascending")}>
                    Price: Low to High
                  </li>
                  <li onClick={() => SortClick("price-descending")}>
                    Price: High to Low
                  </li>
                  <li onClick={() => SortClick("title-ascending")}>
                    Product: A-Z
                  </li>
                  <li onClick={() => SortClick("title-descending")}>
                    Product: Z-A
                  </li>
                </ul>
              )}
            </div>
          </div>

          <ShopGrid products={showingProducts} />

          <div className="pagination">
            <p>
              Showing {showingProducts.length} of {totalCount} products
            </p>
            {totalPages.length > 1 && (
              <ul>
                {totalPages.map((pageNumber) => {
                  return (
                    <li
                      className={parseInt(page) === pageNumber ? "active" : ""}
                      key={pageNumber}
                      onClick={() => OnPageClick(pageNumber)}
                    >
                      <span href="/">{pageNumber}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Fragment>
      )}
    </Layout>
  );
};

export default Shop;
