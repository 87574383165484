import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openCart, closeCart } from "../store/slices/cart-slice";
import ReactWhatsapp from "react-whatsapp";
import { Global } from "../Global";

const Layout = ({ children }) => {
  const { menu } = useSelector((state) => state.data);

  const [isTop, setIsTop] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems, isOpenCart } = useSelector((state) => state.cart);

  let cartTotalPrice = 0;
  let quantityTotal = cartItems.reduce((acc, cur) => {
    return acc + parseInt(cur.quantity, 10);
  }, 0);

  const OpenCart = () => {
    dispatch(openCart());
  };

  const CloseCart = () => {
    dispatch(closeCart());
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsTop(scrollTop < 125);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <header>
        <div className="header">
          <div className="top-header">
            <p>Welcome to brands bazzar</p>
          </div>

          <div className={isTop ? "main-header" : "main-header scrolled-main"}>
            <img
              src="/images/Logo/logo.jpg"
              alt="logo"
              onClick={() => navigate("/")}
            />

            <div className="header-actions">
              <div className="header-count">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24.636 21.679"
                  fill="#fff"
                  stroke="#000"
                  onClick={() => navigate("/wishlist")}
                >
                  <path
                    id="Icon_feather-heart"
                    data-name="Icon feather-heart"
                    d="M23.675,6.281a6.087,6.087,0,0,0-8.611,0L13.891,7.454,12.718,6.281a6.089,6.089,0,1,0-8.611,8.611L5.28,16.065l8.611,8.611L22.5,16.065l1.173-1.173a6.087,6.087,0,0,0,0-8.611Z"
                    transform="translate(-1.573 -3.747)"
                  ></path>
                </svg>
                <span>{wishlistItems.length}</span>
              </div>

              <div
                className="header-count"
                onClick={isOpenCart ? CloseCart : OpenCart}
              >
                <svg
                  fill="#000000"
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  version="1.1"
                >
                  <path d="M27 4.96h-5.975v-1.918c0-1.655-1.346-3-3-3h-3.989c-1.655 0-3 1.345-3 3v1.918h-6.037c-1.104 0-2 0.896-2 2v22.999c0 1.105 0.896 2 2 2h22c1.105 0 2-0.895 2-2v-22.999c0-1.104-0.895-2-2-2h0zM13.037 3.042c0-0.552 0.448-1 1-1h3.989c0.552 0 1 0.448 1 1v1.918h-5.989v-1.918zM27 29.959h-22v-22.999h6.037v2.058s-0.027 0.999 0.994 0.999c1.125 0 1.006-0.999 1.006-0.999v-2.058h5.989v2.058s-0.067 1.004 0.996 1.004c1 0 1.004-1.004 1.004-1.004v-2.058h5.974v22.999z"></path>
                </svg>
                <span>{quantityTotal}</span>
              </div>
            </div>
          </div>

          <div className="bottom-header">
            <ul>
              {menu && menu.map((menu, index) => (
                <li key={index} onClick={() => navigate(menu.menuUrl)}>
                  {menu.menuTitle}
                </li>
              ))}
            </ul>
          </div>

          <div className="mobile-header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => setIsOpenMenu(true)}
            >
              <path
                d="M3 12H21M3 6H21M3 18H15"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <img
              src="/images/Logo/logo.jpg"
              alt="logo"
              onClick={() => navigate("/")}
            />

            <div className="actions">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24.636 21.679"
                className="btn-wishlist"
                onClick={() => navigate("/wishlist")}
              >
                <path
                  id="Icon_feather-heart"
                  data-name="Icon feather-heart"
                  d="M23.675,6.281a6.087,6.087,0,0,0-8.611,0L13.891,7.454,12.718,6.281a6.089,6.089,0,1,0-8.611,8.611L5.28,16.065l8.611,8.611L22.5,16.065l1.173-1.173a6.087,6.087,0,0,0,0-8.611Z"
                  transform="translate(-1.573 -3.747)"
                ></path>
              </svg>
              <svg
                fill="#000000"
                width="22"
                height="22"
                viewBox="0 0 32 32"
                version="1.1"
                onClick={isOpenCart ? CloseCart : OpenCart}
              >
                <path d="M27 4.96h-5.975v-1.918c0-1.655-1.346-3-3-3h-3.989c-1.655 0-3 1.345-3 3v1.918h-6.037c-1.104 0-2 0.896-2 2v22.999c0 1.105 0.896 2 2 2h22c1.105 0 2-0.895 2-2v-22.999c0-1.104-0.895-2-2-2h0zM13.037 3.042c0-0.552 0.448-1 1-1h3.989c0.552 0 1 0.448 1 1v1.918h-5.989v-1.918zM27 29.959h-22v-22.999h6.037v2.058s-0.027 0.999 0.994 0.999c1.125 0 1.006-0.999 1.006-0.999v-2.058h5.989v2.058s-0.067 1.004 0.996 1.004c1 0 1.004-1.004 1.004-1.004v-2.058h5.974v22.999z"></path>
              </svg>
            </div>
          </div>

          <div
            className={
              isOpenMenu ? "sidebar-header" : "sidebar-header closed-sidebar"
            }
          >
            <div className="sidebar-title">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsOpenMenu(false)}
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <div className="mobile-menu">
              {menu && menu.map((menu, index) => (
                <button key={index} onClick={() => navigate(menu.menuUrl)}>
                  {menu.menuTitle}
                </button>
              ))}
            </div>

            {/* <div className="social-icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.194"
                height="14.194"
                viewBox="0 0 14.194 14.194"
              >
                <path
                  id="footerfb"
                  d="M12.673,2.25H1.521A1.521,1.521,0,0,0,0,3.771V14.923a1.521,1.521,0,0,0,1.521,1.521H5.869V11.619h-2V9.347h2V7.616A2.772,2.772,0,0,1,8.837,4.559a12.093,12.093,0,0,1,1.759.153V6.645H9.606A1.136,1.136,0,0,0,8.325,7.872V9.347H10.5l-.349,2.271H8.325v4.826h4.349a1.521,1.521,0,0,0,1.521-1.521V3.771A1.521,1.521,0,0,0,12.673,2.25Z"
                  transform="translate(0 -2.25)"
                  fill="#fff"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.197"
                height="14.194"
                viewBox="0 0 14.197 14.194"
              >
                <path
                  id="footerinsta"
                  d="M7.1,5.7a3.639,3.639,0,1,0,3.639,3.639A3.633,3.633,0,0,0,7.1,5.7Zm0,6.005A2.366,2.366,0,1,1,9.461,9.335,2.37,2.37,0,0,1,7.1,11.7Zm4.637-6.154a.849.849,0,1,1-.849-.849A.847.847,0,0,1,11.732,5.547Zm2.41.862A4.2,4.2,0,0,0,13,3.434a4.228,4.228,0,0,0-2.974-1.147c-1.172-.067-4.684-.067-5.856,0A4.222,4.222,0,0,0,1.191,3.431,4.214,4.214,0,0,0,.045,6.405c-.067,1.172-.067,4.684,0,5.856a4.2,4.2,0,0,0,1.147,2.974,4.234,4.234,0,0,0,2.974,1.147c1.172.067,4.684.067,5.856,0A4.2,4.2,0,0,0,13,15.236a4.228,4.228,0,0,0,1.147-2.974c.067-1.172.067-4.681,0-5.853Zm-1.514,7.111a2.4,2.4,0,0,1-1.349,1.349,15.643,15.643,0,0,1-4.184.285,15.765,15.765,0,0,1-4.184-.285,2.4,2.4,0,0,1-1.349-1.349,15.643,15.643,0,0,1-.285-4.184,15.765,15.765,0,0,1,.285-4.184A2.4,2.4,0,0,1,2.911,3.8,15.643,15.643,0,0,1,7.1,3.516a15.765,15.765,0,0,1,4.184.285,2.4,2.4,0,0,1,1.349,1.349,15.643,15.643,0,0,1,.285,4.184A15.634,15.634,0,0,1,12.628,13.519Z"
                  transform="translate(0.005 -2.238)"
                  fill="#fff"
                ></path>
              </svg>
            </div> */}
          </div>

          {isOpenCart && (
            <div className="side-cart">
              {cartItems.length > 0 ? (
                <Fragment>
                  <div
                    className={
                      cartItems.length > 2
                        ? "products products-height"
                        : "products"
                    }
                  >
                    {cartItems.map((item, index) => {
                      item.salePrice === 0
                        ? (cartTotalPrice += item.price * item.quantity)
                        : (cartTotalPrice += item.salePrice * item.quantity);
                      quantityTotal += item.quantity;
                      return (
                        <div className="product" key={index}>
                          <div className="product-description">
                            <h6>{item.productName}</h6>
                            {/* <p className="product-brand">Brand: {item.brand}</p> */}
                            {item.salePrice !== 0 ? (
                              <div className="product-pricing">
                                <p className="product-price">
                                  {item.quantity} x
                                </p>
                                <strike>
                                  <p className="product-price">${item.price}</p>
                                </strike>
                                <p className="product-sale-price">
                                  ${item.salePrice}
                                </p>
                              </div>
                            ) : (
                              <p className="product-price">
                                {item.quantity} x ${item.price}
                              </p>
                            )}
                          </div>
                          <div className="product-image">
                            <img src={Global.PHOTO_URL + item.image} alt="product" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="side-cart-pricing">
                    <p>Total</p>
                    <p>{cartTotalPrice.toFixed(2)}$</p>
                  </div>
                  <div className="side-cart-actions">
                    <button
                      className="btn-cart"
                      onClick={() => navigate("/cart")}
                    >
                      View Cart
                    </button>
                    <button
                      className="btn-checkout"
                      onClick={() => navigate("/checkout")}
                    >
                      Checkout
                    </button>
                  </div>
                </Fragment>
              ) : (
                <div className="empty-cart">
                  <p>No items found in cart</p>
                </div>
              )}
            </div>
          )}
        </div>
      </header>
      <div className="header-whatsapp">
        <ReactWhatsapp
          number="96171031714"
          message=""
          className="whatsapp-icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="#fff"
            viewBox="0 0 16 16"
          >
            {" "}
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />{" "}
          </svg>
        </ReactWhatsapp>
      </div>
      {children}
      <footer className="footer">
        <div className="footer-top">
          <div className="footer-info">
            <h3>Get in touch</h3>
            <ul>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />{" "}
                </svg>
                <a href="tel:+96171031714">+961 71 031 714 </a>
              </li>
            </ul>
          </div>

          <div className="footer-info">
            <h3>Follow us</h3>
            <ul>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.194"
                  height="14.194"
                  viewBox="0 0 14.194 14.194"
                >
                  <path
                    id="footerfb"
                    d="M12.673,2.25H1.521A1.521,1.521,0,0,0,0,3.771V14.923a1.521,1.521,0,0,0,1.521,1.521H5.869V11.619h-2V9.347h2V7.616A2.772,2.772,0,0,1,8.837,4.559a12.093,12.093,0,0,1,1.759.153V6.645H9.606A1.136,1.136,0,0,0,8.325,7.872V9.347H10.5l-.349,2.271H8.325v4.826h4.349a1.521,1.521,0,0,0,1.521-1.521V3.771A1.521,1.521,0,0,0,12.673,2.25Z"
                    transform="translate(0 -2.25)"
                    fill="#fff"
                  ></path>
                </svg>
                <a href="https://www.facebook.com/profile.php?id=100094215197764&mibextid=ZbWKwL" target="_blank" rel="noreferrer">Facebook</a>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.197"
                  height="14.194"
                  viewBox="0 0 14.197 14.194"
                >
                  <path
                    id="footerinsta"
                    d="M7.1,5.7a3.639,3.639,0,1,0,3.639,3.639A3.633,3.633,0,0,0,7.1,5.7Zm0,6.005A2.366,2.366,0,1,1,9.461,9.335,2.37,2.37,0,0,1,7.1,11.7Zm4.637-6.154a.849.849,0,1,1-.849-.849A.847.847,0,0,1,11.732,5.547Zm2.41.862A4.2,4.2,0,0,0,13,3.434a4.228,4.228,0,0,0-2.974-1.147c-1.172-.067-4.684-.067-5.856,0A4.222,4.222,0,0,0,1.191,3.431,4.214,4.214,0,0,0,.045,6.405c-.067,1.172-.067,4.684,0,5.856a4.2,4.2,0,0,0,1.147,2.974,4.234,4.234,0,0,0,2.974,1.147c1.172.067,4.684.067,5.856,0A4.2,4.2,0,0,0,13,15.236a4.228,4.228,0,0,0,1.147-2.974c.067-1.172.067-4.681,0-5.853Zm-1.514,7.111a2.4,2.4,0,0,1-1.349,1.349,15.643,15.643,0,0,1-4.184.285,15.765,15.765,0,0,1-4.184-.285,2.4,2.4,0,0,1-1.349-1.349,15.643,15.643,0,0,1-.285-4.184,15.765,15.765,0,0,1,.285-4.184A2.4,2.4,0,0,1,2.911,3.8,15.643,15.643,0,0,1,7.1,3.516a15.765,15.765,0,0,1,4.184.285,2.4,2.4,0,0,1,1.349,1.349,15.643,15.643,0,0,1,.285,4.184A15.634,15.634,0,0,1,12.628,13.519Z"
                    transform="translate(0.005 -2.238)"
                    fill="#fff"
                  ></path>
                </svg>
                <a href="https://instagram.com/brands.bazzar?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer">Instagram</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>Copyright © 2023, All Rights Reserved.</p>
        </div>
      </footer>
    </Fragment>
  );
};

export default Layout;
