import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ManageCity = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [city, setCity] = useState({
    cityName: "",
    shippingCost: 0,
    isPublished: true,
  });
  const [loading, setLoading] = useState(true);

  const AddCity = async () => {
    const response = await fetch(Global.API_URL + "City/AddCity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cityName: city.cityName,
        shippingCost: city.shippingCost,
        isPublished: city.isPublished,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      toast.success(result.message);
      navigate("/admin/cities");
    } else {
      toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    }
  };

  const EditCity = async () => {
    const response = await fetch(Global.API_URL + "City/EditCity/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cityName: city.cityName,
        shippingCost: city.shippingCost,
        isPublished: city.isPublished,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      toast.success(result.message);
      navigate("/admin/cities");
    } else {
      toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    }
  };

  useEffect(() => {
    const GetCity = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(Global.API_URL + "City/GetCity/" + id, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setCity(result.message);
              setLoading(false);
            } else {
              toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
            }
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } catch (error) {
          console.log(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetCity();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/cities">Back</NavLink>
          <button onClick={parseInt(id) === 0 ? AddCity : EditCity}>
            Submit
          </button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group card-check">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={city.isPublished}
                      onChange={(e) =>
                        setCity((prevState) => ({
                          ...prevState,
                          isPublished: e.target.checked,
                        }))
                      }
                    />
                    <label>Publish</label>
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      City Name{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="City name"
                      value={city.cityName}
                      onChange={(e) =>
                        setCity((prevState) => ({
                          ...prevState,
                          cityName: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Shipping Cost{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="number"
                      min="0"
                      placeholder="Shipping cost"
                      value={city.shippingCost}
                      onChange={(e) =>
                        setCity((prevState) => ({
                          ...prevState,
                          shippingCost: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageCity;
