import React, { useState, useRef, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ListCategory = () => {
  const [categories, setCategories] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const pageLimit = 10;
  const activePag = useRef(0);
  const navigate = useNavigate();

  let paggination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangePage = (i) => {
    activePag.current = i;

    changeData(activePag.current * pageLimit);
  };

  const changeData = async (skip) => {
    try {
      const response = await fetch(
        Global.API_URL + "Category/GetCategories?skip=" + skip + "&take=" + pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          setCategories(result.message);
          setTotalCount(result.count);
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      console.log(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    const GetCategories = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Category/GetCategories?skip=0&take=" + pageLimit,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setCategories(result.message);
            setTotalCount(result.count);
            setLoading(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetCategories();
  }, []);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/category/0">+ New Category</NavLink>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="table-responsive">
                  <div>
                    <table className="card-table shadow-hover dataTable">
                      <thead>
                        <tr role="row">
                          <th>Id</th>
                          <th>Category Name</th>
                          <th>Category Image</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((category, index) => (
                          <tr role="row" key={index}>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {category.categoryId}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {category.categoryName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                <img
                                  src={Global.PHOTO_URL + category.categoryImage}
                                  alt="category"
                                  height="75"
                                />
                              </span>
                            </td>
                            <td>
                              <div className="dropdown dropdown">
                                <div
                                  className="btn-link i-false dropdown-toggle"
                                  id="react-aria3069930207-3"
                                  aria-expanded="false"
                                  data-bs-toggle="dropdown"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      navigate("/admin/category/" + category.categoryId)
                                    }
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="content-pagination">
                      <div className="pagination-info">
                        Showing {categories.length} of {totalCount} entries
                      </div>
                      <div className="pagination-button">
                        <button
                          className="previous"
                          onClick={() =>
                            activePag.current > 0 &&
                            ChangePage(activePag.current - 1)
                          }
                        >
                          Previous
                        </button>
                        <span>
                          {paggination.map((number, index) => (
                            <button
                              className={
                                activePag.current === index
                                  ? "current active"
                                  : "current"
                              }
                              key={index}
                              onClick={() => ChangePage(index)}
                            >
                              {number}
                            </button>
                          ))}
                        </span>
                        <button
                          className="next"
                          onClick={() =>
                            activePag.current + 1 < paggination.length &&
                            ChangePage(activePag.current + 1)
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListCategory;
