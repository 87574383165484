import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ManageMenu = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [menu, setMenu] = useState({
    menuTitle: "",
    menuOrder: 0,
    menuUrl: "",
    menuTypeId: 0,
    categoryId: 0,
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const AddMenu = async () => {
    const response = await fetch(Global.API_URL + "Menu/AddMenu", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        menuTitle: menu.menuTitle,
        categoryId: menu.categoryId,
        menuUrl: menu.menuUrl,
        menuOrder: menu.menuOrder,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      toast.success(result.message);
      navigate("/admin/menu");
    } else {
      toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    }
  };

  const EditMenu = async () => {
    const response = await fetch(Global.API_URL + "Menu/EditMenu/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        menuTitle: menu.menuTitle,
        categoryId: menu.categoryId,
        menuUrl: menu.menuUrl,
        menuOrder: menu.menuOrder,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      toast.success(result.message);
      navigate("/admin/menu");
    } else {
      toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    }
  };

  const ChangeCategory = (value, name) => {
    if (value !== 0) {
      setMenu((prevState) => ({
        ...prevState,
        categoryId: value,
        menuUrl: "/shop/" + name.replaceAll(" ", "-") + "/" + value + "?sort=new-arrival&page=1",
      }));
    } else {
      setMenu((prevState) => ({
        ...prevState,
        categoryId: value,
        menuUrl: "",
      }));
    }
  };

  useEffect(() => {
    const GetCategories = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Category/GetCategories?skip=0&take=100",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setCategories(result.message);
            setLoading(false);
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } else {
          toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        }
      } catch (error) {
        console.log(error.Message || "Something went wrong");
      }
    };

    GetCategories();
  }, []);

  useEffect(() => {
    const GetMenu = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(Global.API_URL + "Menu/GetMenu/" + id, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setMenu(result.message);
              setLoading(false);
            } else {
              toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
            }
          } else {
            toast.error(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
          }
        } catch (error) {
          console.log(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetMenu();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/menu">Back</NavLink>
          <button onClick={parseInt(id) === 0 ? AddMenu : EditMenu}>
            Submit
          </button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Menu Title{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Menu title"
                      value={menu.menuTitle}
                      onChange={(e) =>
                        setMenu((prevState) => ({
                          ...prevState,
                          menuTitle: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Menu Order{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="number"
                      min="0"
                      placeholder="Menu order"
                      value={menu.menuOrder}
                      onChange={(e) =>
                        setMenu((prevState) => ({
                          ...prevState,
                          menuOrder: parseInt(e.target.value),
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>Category</label>
                    <select
                      value={menu.categoryId}
                      onChange={(e) =>
                        ChangeCategory(
                          parseInt(e.target.value),
                          e.target.options[e.target.selectedIndex].title
                        )
                      }
                    >
                      <option value={0}>-- Optional --</option>
                      {categories.map((category, index) => (
                        <option
                          key={index}
                          value={category.categoryId}
                          title={category.categoryName}
                        >
                          {category.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="card-group">
                    <label>
                      Menu Url{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Menu url"
                      value={menu.menuUrl}
                      disabled={menu.categoryId !== 0}
                      onChange={(e) =>
                        setMenu((prevState) => ({
                          ...prevState,
                          menuUrl: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageMenu;
